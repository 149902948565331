// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-menu {
  list-style-type: none;
  padding: 0;
}

.nav-item {
  margin-bottom: 10px;
}

.sub-menu {
  list-style-type: none;
  padding-left: 20px;
}

.sub-menu .nav-item {
  margin-bottom: 5px;
}

.bi {
  margin-right: 5px;
}
/* People.css */

/* Overall container */
.container {
max-width: 800px;
margin: 0 auto;
}

/* Table styling */
table {
width: 100%;
border-collapse: collapse;
margin-bottom: 20px;
}

table th {

padding: 50px;
text-align: left;
}

table td {
border: 1px solid #ddd;
padding: 10px;
width: 100px;
}

/* Advisor, External Advisor, Members container */
.section-container {
margin-bottom: 80px;

}

/* Faculty container */
.faculty {
display: flex;
align-items: center;
border: 51px solid #ccc;
padding: 20px;
}
.h4{
padding-left: 20px;
}

.titleBold{
font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;AACA,eAAe;;AAEf,sBAAsB;AACtB;AACA,gBAAgB;AAChB,cAAc;AACd;;AAEA,kBAAkB;AAClB;AACA,WAAW;AACX,yBAAyB;AACzB,mBAAmB;AACnB;;AAEA;;AAEA,aAAa;AACb,gBAAgB;AAChB;;AAEA;AACA,sBAAsB;AACtB,aAAa;AACb,YAAY;AACZ;;AAEA,iDAAiD;AACjD;AACA,mBAAmB;;AAEnB;;AAEA,sBAAsB;AACtB;AACA,aAAa;AACb,mBAAmB;AACnB,uBAAuB;AACvB,aAAa;AACb;AACA;AACA,kBAAkB;AAClB;;AAEA;AACA,iBAAiB;AACjB","sourcesContent":[".nav-menu {\n  list-style-type: none;\n  padding: 0;\n}\n\n.nav-item {\n  margin-bottom: 10px;\n}\n\n.sub-menu {\n  list-style-type: none;\n  padding-left: 20px;\n}\n\n.sub-menu .nav-item {\n  margin-bottom: 5px;\n}\n\n.bi {\n  margin-right: 5px;\n}\n/* People.css */\n\n/* Overall container */\n.container {\nmax-width: 800px;\nmargin: 0 auto;\n}\n\n/* Table styling */\ntable {\nwidth: 100%;\nborder-collapse: collapse;\nmargin-bottom: 20px;\n}\n\ntable th {\n\npadding: 50px;\ntext-align: left;\n}\n\ntable td {\nborder: 1px solid #ddd;\npadding: 10px;\nwidth: 100px;\n}\n\n/* Advisor, External Advisor, Members container */\n.section-container {\nmargin-bottom: 80px;\n\n}\n\n/* Faculty container */\n.faculty {\ndisplay: flex;\nalign-items: center;\nborder: 51px solid #ccc;\npadding: 20px;\n}\n.h4{\npadding-left: 20px;\n}\n\n.titleBold{\nfont-weight: bold;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
